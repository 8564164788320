<template>
  <div class="noticelist_wrap">
    <h4>공지사항</h4>
    <ol class="notice_list">
      <li v-for="noticeItem in noticeList" :key="noticeItem.Idx">
        <router-link :to="`/notice/detail/${noticeItem.Idx}`" class="notice_tit">
          <strong class="title">{{ noticeItem.Title }}</strong>
          <span class="date">{{ noticeItem.RegDT }}</span>
        </router-link>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "NoticeListWrap",
  props: {
    noticeList: {
      type: Array,
    },
    goDetail: {
      type: Function,
    },
  },
}
</script>
