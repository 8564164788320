<template>
  <section class="sub_sec">
    <NoticeListWrap :noticeList="noticeList" />
    <template v-if="more">
      <SubmitButton :btnObj="{ class: 'more', text: '더보기' }" @btnEvt="showMore" />
    </template>
  </section>
</template>

<script>
import SubmitButton from "@/components/SubmitButton.vue"
import NoticeListWrap from "@/components/Notice/NoticeListWrap.vue"

export default {
  name: "NoticeList",
  components: {
    SubmitButton,
    NoticeListWrap,
  },
  data() {
    return {
      more: false,
      noticeList: [],
      currentPage: 0,
    }
  },
  created() {
    this.fetchNoticeList()
  },
  methods: {
    showMore() {
      this.currentPage++
      this.fetchNoticeList()
    },
    async fetchNoticeList() {
      const httpData = {}
      httpData.Header = {
        CmdType: "010002",
        CallApp: "www",
        TransactionID: this.$guid(),
      }
      httpData.Body = {
        Length: 7,
        Offset: this.currentPage * 6,
      }

      try {
        const { data } = await this.$SRSServer(httpData)
        const sliceData = data.Body.splice(6, 1)

        if (sliceData.length === 0) this.more = false
        else this.more = true
        if (this.currentPage !== 0) {
          const concatArr = this.noticeList.concat(data.Body)
          this.noticeList = concatArr
        } else {
          this.noticeList = data.Body
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
